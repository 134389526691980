import { useNavigate } from "react-router-dom";

import { Grid, Typography, Divider } from "@mui/material";
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RateReviewIcon from '@mui/icons-material/RateReview';

import { memo, useCallback, useEffect, useState } from "react";

import Search from "../components/Search.js";
import Spinner from "../components/Spinner.js";
import { isFuzzyMatch, useSnackbar, jwt } from "../utils/index.js";
import {
	getUserAssignments,
	getTeamsData,
} from "../api/index.js";
import { formatDate } from '../utils/timestamps.js';

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	lockIcon: {
		cursor: "pointer",
		color: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
	assignmentButton: {
		color: theme.palette.third.main,
		marginRight: "0.5rem",
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
	select: {
		color: theme.palette.white.main, // Set the desired color for the select
		"&:before": {
			borderColor: theme.palette.white.main, // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: theme.palette.white.main, // Set the desired color for the select's border when focused
		},
	},
	titleBox: {
		backgroundColor: theme.palette.secondary.main,
		color: "inherit",
		width: "100%",
		padding: "0.2rem 0.5rem",
		marginBottom: "0.5rem",
		display: "flex",
		minHeight: "60px",
		borderRadius: theme.spacing(2),
		alignItems: "center",
	},
	reviewChip: {
		backgroundColor: theme.palette.light.main,
		"& .MuiChip-label": {
			color: "white",
		},
		"&:hover": {
			backgroundColor: theme.palette.light.dark,
		},
	},
	documentLink: {
		color: theme.palette.third.main,
		textDecoration: "underline",
		textDecorationColor: theme.palette.third.main,
		marginRight: "0.5rem",
		paddingTop: "0.5rem",
		fontSize: "0.9rem",
		"&:hover": {
			color: theme.palette.third.dark,
			textDecoration: "underline",
		},
	},
}));

const Assignments = () => {
	const { error } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [assignments, setAssignments] = useState([]);
	const [reviews, setReviews] = useState([]);

	const [tasksSearchFilter, setTasksSearchFilter] = useState("");
	const [filteredAssignments, setFilteredAssignments] = useState([]);
	const [filteredReviews, setFilteredReviews] = useState([]);
	const [teams, setTeams] = useState([{ value: "All teams", text: "All teams" }]);
	const [selectedTeam, setSelectedTeam] = useState("All teams");

	const classes = useStyles();
	const navigate = useNavigate();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const user = jwt.decode();
				const { success: scs, assignments: assigns, reviews: revs } = await getUserAssignments(user.id);
				// Assignments handling
				const cleanAssignments = assigns.filter((item) => item !== null);
				cleanAssignments.reverse();
				// Reviews handling
				const cleanReviews = revs.filter((item) => item !== null);
				cleanReviews.reverse();
				if (scs) {
					setAssignments(cleanAssignments);
					setReviews(cleanReviews);
				} else {
					error();
				}

				const { success: scst, teams: tms } = await getTeamsData();
				if (scst) {
					const teamsList = tms.map((e) => ({ value: e.name, text: e.name }));
					teamsList.unshift({ value: "All teams", text: "All teams" });
					setTeams(teamsList);
				} else {
					error();
				}
			} catch {
				error();
			}

			setIsLoading(false);
		},
		[error],
	);

	useEffect(() => {
		setFilteredAssignments(
			assignments
				.filter((us) => isFuzzyMatch(`${us?.text} ${us.teamName} ${us.publicationTitle}`, tasksSearchFilter))
				.filter((us) => (selectedTeam === "All teams" ? true : us.teamName === selectedTeam)),
		);
		setFilteredReviews(
			reviews
				.filter((us) => isFuzzyMatch(`${us.teamName} ${us.publicationTitle}`, tasksSearchFilter))
				.filter((us) => (selectedTeam === "All teams" ? true : us.teamName === selectedTeam)),
		);
	}, [assignments, reviews, selectedTeam, tasksSearchFilter]);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					className={classes.titleBox}
					justifyContent="space-between"
				>
					<Grid
						item
						ml={2}
						display="flex"
						flexDirection="row"
						alignItems="center"
						height="60px"
					>
						<Typography variant="h6" color="#ffffff">
							{"Your tasks"}
						</Typography>
					</Grid>
					<Grid
						item
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
					>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
							<Select
								className={classes.select}
								value={selectedTeam}
								mr={1}
								onChange={(event) => {
									setSelectedTeam(event.target.value);
								}}
							>
								{
									teams.map((e, ind) => (
										<MenuItem key={ind} value={e.value}>{e?.text}</MenuItem>
									))
								}
							</Select>
						</FormControl>
						<Search value={tasksSearchFilter} width="500px" onChange={(event) => setTasksSearchFilter(event.target.value)} />
					</Grid>
				</Grid>
				{
					<Grid
						container
						item
						mt={2}
						mb={2}
						width="100%"
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
					>
						<Grid
							container
							item
							width="100%"
							xs={5.9}
							display="flex"
							flexDirection="column"
							backgroundColor="secondary.main"
							borderRadius="20px"
						>
							<Grid
								container
								item
								width="100%"
								pl={2}
								display="flex"
								minHeight="50px"
								maxHeight="50px"
								alignItems="center"
								backgroundColor="third.main"
								sx={{
									borderTopLeftRadius: "20px",
									borderTopRightRadius: "20px",
								}}
							>
								<Grid
									item
									xs={6}
								>
									<Typography variant="h6" color="secondary.main">
										{"Pending assignments"}
									</Typography>
								</Grid>
							</Grid>
							{
								filteredAssignments.length === 0 && (
									<Typography color="gray" pl={2} pt={1}>
										{"No pending assignments"}
									</Typography>
								)
							}
							<List dense>
								{filteredAssignments.map((e, ind) => (
									<ListItem
										key={ind}
										secondaryAction={(
											<IconButton
												edge="end"
												onClick={() => {
													navigate(`/teams/${e.teamId}/publications/${e.publicationId}`);
												}}
											>
												<AutoFixHighIcon className={classes.assignmentButton} />
											</IconButton>
										)}
									>
										<ListItemAvatar>
											<Avatar sx={{ bgcolor: "third.main" }}>
												<AssignmentTurnedInIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={(
												<Grid
													container
													style={{
														alignItems: "baseline",
														flexDirection: "column",
													}}
												>
													<Typography fontSize="1.1rem" color="green.main">
														{e?.text}
													</Typography>
													<Typography
														fontSize="0.9rem"
														color="#fff"
													>
														{`Publication: ${e.publicationTitle}`}
													</Typography>
													<Typography
														fontSize="0.9rem"
														color="#fff"
													>
														{`Team: ${e.teamName}`}
													</Typography>
													{e.deadline
														&& (
															<Grid
																container
																flexDirection="row"
															>
																<Typography
																	fontSize="0.75rem"
																	color="#fff"
																	sx={{
																		marginRight: "0.2rem",
																	}}
																>
																	{"Deadline: "}
																</Typography>
																<Typography
																	fontSize="0.75rem"
																	color={Date.now() > new Date(e.deadline).getTime() ? "error.main" : "green.main"}
																>
																	{formatDate(e.deadline)}
																</Typography>
															</Grid>
														)}
													<Typography color={e.resolved ? "gray" : "#ffffff"} fontSize="0.7rem">
														{e.timestamp.replace("T", " ").replace("Z", "")}
													</Typography>
												</Grid>
											)}
										/>
									</ListItem>
								))}
							</List>
						</Grid>
						<Grid
							container
							item
							width="100%"
							xs={5.9}
							display="flex"
							flexDirection="column"
							backgroundColor="secondary.main"
							borderRadius="20px"
						>
							<Grid
								container
								item
								width="100%"
								pl={2}
								display="flex"
								minHeight="50px"
								maxHeight="50px"
								alignItems="center"
								backgroundColor="third.main"
								sx={{
									borderTopLeftRadius: "20px",
									borderTopRightRadius: "20px",
								}}
							>
								<Grid
									item
									xs={6}
								>
									<Typography variant="h6" color="secondary.main">
										{"Pending reviews"}
									</Typography>
								</Grid>
							</Grid>
							{
								filteredReviews.length === 0 && (
									<Typography color="gray" pl={2} pb={1} pt={1}>
										{"No pending reviews"}
									</Typography>
								)
							}
							{filteredReviews.length > 0 && (
								<Timeline
									sx={{
										[`& .${timelineItemClasses.root}:before`]: {
											flex: 0,
											padding: 0,
										},
									}}
								>
									{filteredReviews.map((e, ind) => (
										<TimelineItem key={ind}>
											<TimelineSeparator>
												<TimelineDot
													color="white"
												>
													<RateReviewIcon color="light" />
												</TimelineDot>
												{ind !== filteredReviews.length - 1 && <TimelineConnector />}
											</TimelineSeparator>
											<TimelineContent>
												<Grid
													container
													mt={1}
													width="100%"
												>
													<Typography
														fontSize="1.1rem"
														color="green.main"
														sx={{
															mr: 1,
														}}
													>
														{`${e.type.charAt(0).toUpperCase() + e.type.slice(1)} review`}
													</Typography>

													<LockOpenIcon className={classes.lockIcon} />

													<Typography
														color="#ffffff"
														fontSize="0.7rem"
														sx={{
															pt: 0.7,
															ml: 1,
														}}
													>
														{e.timestamp.replace("T", " ").replace("Z", "")}
													</Typography>
												</Grid>

												<Typography
													fontSize="0.9rem"
													color="third.main"
												>
													{`Publication: ${e.publicationTitle}`}
												</Typography>
												<Typography
													fontSize="0.9rem"
													color="third.main"
												>
													{`Team: ${e.teamName}`}
												</Typography>
												{e.deadline && (
													<Grid
														container
														flexDirection="row"
														style={{
															alignItems: "center",
														}}
													>
														<Typography fontSize="0.9rem" color="#ffffff">
															{`Deadline: ${e.deadline.replace("T", " ").replace("Z", "")}`}
														</Typography>
													</Grid>
												)}
												<Grid
													container
													mb={0.5}
													flexDirection="column"
													alignContent="flex-start"
												>
													{/* Document for review handling */}
													<Grid
														container
														flexDirection="row"
														alignContent="flex-start"
													>
														{
															e.documentForReviewSavedName ? (
																<>
																	<Typography
																		fontSize="0.9rem"
																		color="#fff"
																		sx={{
																			mr: 1,
																			paddingTop: "0.5rem",
																		}}
																	>
																		{e.type === "internal" ? "Document for review: " : "Document for revisions: "}
																	</Typography>
																	<Link
																		className={classes.documentLink}
																		href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${e.documentForReviewFolder}/${e.documentForReviewSavedName}?token=${jwt.getToken()}`}
																		target="_blank"
																	>
																		{`Version ${e.documentForReviewVersion}`}
																	</Link>
																</>
															) : (
																<Typography
																	fontSize="0.9rem"
																	color="gray"
																	sx={{
																		mr: 1,
																		pt: 0.5,
																	}}
																>
																	{e.type === "internal" ? "No document for review selected" : "No document for revisions selected"}
																</Typography>
															)
														}
													</Grid>

													{/* Reviewers comments handling */}
													{
														e.type === "external" && (
															<Grid
																container
																mb={0.5}
																flexDirection="row"
																alignContent="flex-start"
															>
																<Typography
																	sx={{
																		mt: 0.5,
																		mr: 1,
																	}}
																	fontSize="0.9rem"
																	color={e.externalReviewersCommentsSavedName ? "#fff" : "gray"}
																>
																	{e.externalReviewersCommentsSavedName ? "Reviewers' comments: " : "No reviewers' comments"}
																</Typography>
																{
																	e.externalReviewersCommentsSavedName && (
																		<Link
																			fontSize="0.9rem"
																			href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${e.externalReviewersCommentsFolder}/${e.externalReviewersCommentsSavedName}?token=${jwt.getToken()}`}
																			target="_blank"
																			color="third.main"
																			sx={{
																				mt: 0.5,
																				mr: 1,
																			}}
																		>
																			{`${e.externalReviewersCommentsOriginalName.slice(0, 30)}
																	${e.externalReviewersCommentsOriginalName.length > 30 ? "..." : ""}`}
																		</Link>
																	)
																}
															</Grid>

														)
													}
												</Grid>
												<Chip
													label="Manage in publication"
													className={classes.reviewChip}
													sx={{
														mr: 0.5,
													}}
													aria-controls={open ? "basic-menu" : undefined}
													aria-haspopup="true"
													aria-expanded={open ? "true" : undefined}
													onClick={() => {
														console.log(e);
														navigate(`/teams/${e.teamId}/publications/${e.publicationId}`);
													}}
												/>
												{
													ind !== reviews.length - 1 && (
														<Divider
															sx={{
																mt: 3,
																height: 0.001,
															}}
														/>
													)
												}

											</TimelineContent>
										</TimelineItem>
									))}
								</Timeline>
							)}
						</Grid>
					</Grid>
				}
			</Grid>
		</>
	);
};

export default memo(Assignments);
