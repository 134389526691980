import { useState, useEffect } from "react";
import MDEditor from "@uiw/react-md-editor";
import { makeStyles } from '@mui/styles';

import {
	Grid,
	Typography,
	FormControl,
	MenuItem,
	Select,
} from "@mui/material";

import {
	saveProjectInformation,
	saveMarkdownTheme,
	getMarkdownTheme,
} from "../api/index.js";
import { calculateTimestampToShow } from "../utils/timestamps.js";

const useStyles = makeStyles((theme) => ({
	select: {
		padding: "0px 0px 0px 0px !important",
		color: theme.palette.primary.main, // Set the desired color for the select
		"&:before": {
			borderColor: theme.palette.primary.main, // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: theme.palette.primary.main, // Set the desired color for the select's border when focused
		},
	},
}));

const GenericMdEditor = ({
	screen,
	element,
	myid,
	setIsLoading,
	success,
	error,
	mdHeader,
	readonly = false,
}) => {
	const [markDownValue, setMarkDownValue] = useState("");
	const [prevText, setPrevText] = useState("");
	const [autoSaveTimerId, setAutoSaveTimerId] = useState(null);
	const [lastSaveTimestamp, setLastSaveTimestamp] = useState("");
	const [timestampToShow, setTimestampToShow] = useState("");
	const [pageStart, setPageStart] = useState(true);
	const classes = useStyles();

	const themes = [
		{
			value: "light",
			label: "light",
		},
		{
			value: "dark",
			label: "dark",
		},
	];
	const [themeMode, setThemeMode] = useState("light");

	const getUserMarkdownTheme = async () => {
		if (readonly) return;
		const res = await getMarkdownTheme(myid);
		if (res.success) {
			setThemeMode(res.message);
		} else {
			setThemeMode("light");
		}
	};

	const calculateTimestamp = (timestamp) => {
		const stringToShow = calculateTimestampToShow(timestamp);
		setTimestampToShow(stringToShow);
	};

	const saveChanges = async () => {
		if (prevText === markDownValue) {
			return;
		}

		setLastSaveTimestamp(new Date());
		try {
			let ret = {};
			switch (screen) {
				case "ProjectInfo": {
					ret = await saveProjectInformation(element._id, "fullDescription", markDownValue);
					break;
				}

				default: {
					break;
				}
			}

			console.log(ret.message);
			if (ret.success) {
				success(ret.message);
			} else {
				error(ret.message);
			}
		} catch { /* empty */ }
	};

	const saveTheme = async (e) => {
		setThemeMode(e.target.value);
		if (readonly) return;
		setIsLoading(true);
		try {
			const { success: successCode, message } = await saveMarkdownTheme(myid, e.target.value);
			console.log(message);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		setIsLoading(false);
	};

	useEffect(() => {
		getUserMarkdownTheme();
		switch (screen) {
			case "ProjectInfo": {
				setMarkDownValue(element.fullDescription);
				setPrevText(element.fullDescription);
				break;
			}

			default: {
				break;
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [element]);

	useEffect(() => {
		if (lastSaveTimestamp) {
			calculateTimestamp(lastSaveTimestamp); // This will always use latest value of count
		}
	}, [lastSaveTimestamp]);

	useEffect(() => {
		if (pageStart) {
			setPageStart(false);
		} else {
			// stop previous save timer if exists
			if (autoSaveTimerId) {
				clearTimeout(autoSaveTimerId);
			}

			// set new auto save timer
			const newTimeout = setTimeout(saveChanges, 3000);
			setAutoSaveTimerId(newTimeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markDownValue]);

	return (
		<Grid
			container
			item
			display="flex"
			flexDirection="column"
			backgroundColor="secondary.main"
			borderRadius="20px"
		>
			{/* Title */}
			<Grid
				container
				item
				width="100%"
				pl={2}
				display="flex"
				minHeight="50px"
				maxHeight="50px"
				alignItems="center"
				backgroundColor="third.main"
				sx={{
					borderTopLeftRadius: "20px",
					borderTopRightRadius: "20px",
				}}
			>
				<Grid
					item
					xs={6}
				>
					<Typography variant="h6" color="secondary.main">
						{mdHeader}
					</Typography>
				</Grid>
				<Grid
					item
					xs={6}
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="flex-end"
					pr={2}
				>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
						<Select
							className={classes.select}
							label="Mentions"
							value={themeMode}
							onChange={(e) => {
								saveTheme(e);
							}}
						>
							{
								themes.map((e, ind) => (
									<MenuItem key={ind} value={e.value}>{e.label}</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			{/* Editor */}
			<Grid
				data-color-mode={themeMode}
				sx={{
					p: 2,
				}}
			>
				<MDEditor
					value={markDownValue}
					height={600}
					maxHeight={600}
					fullscreen={false}
					overflow={false}
					tabSize={2}
					preview={readonly ? "preview" : "live"}
					style={{ zIndex: 100 }}
					hideToolbar={readonly}
					onChange={setMarkDownValue}
				/>
			</Grid>
			{
				lastSaveTimestamp
					? (
						<Typography color="gray" sx={{ ml: 2, fontSize: "0.9rem" }}>
							{`Changes saved`}
						</Typography>
					)
					: ''
			}
		</Grid>
	);
};

export default GenericMdEditor;
