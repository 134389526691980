import { Modal, Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useEffect, useState } from "react";

import { ErrorBackgroundButton, ThirdBorderButton } from "../Buttons.js";

import colors from "../../_colors.scss";

const useStyles = makeStyles(() => ({
	main: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		boxShadow: 24,
		borderRadius: "10px",
		outline: "none",
		maxHeight: "80vh",
		overflowY: "auto",
	},
}));

const AreYouSurePopup = ({
	open: popupOpen = false,
	title = "",
	content = "",
	titleColor = "primary",
	titleBackgroundColor = "third",
	color = "third",
	backgroundColor = "secondary",
	width = "100%",
	maxWidth = "900px",
	onAccept,
	onDecline,
	actionTitle = "Delete",
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(popupOpen);

	useEffect(() => {
		setOpen(popupOpen);
	}, [popupOpen]);

	return (
		<Modal
			open={open}
		>
			<Box
				className={classes.main}
				width={width}
				sx={{ maxWidth }}
			>
				<Grid
					width="100%"
					px={2}
					py={1}
					color={colors?.[titleColor] || titleColor}
					backgroundColor={colors?.[titleBackgroundColor] || titleBackgroundColor}
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
					sx={{
						borderTopLeftRadius: "10px",
						borderTopRightRadius: "10px",
					}}
				>
					<Typography variant="h6" component="h2">{title}</Typography>
				</Grid>
				<Grid
					width="100%"
					px={4}
					py={2}
					color={colors?.[color] || color}
					backgroundColor={colors?.[backgroundColor] || backgroundColor}
					sx={{ fontSize: "1.1rem" }}
				>
					{content}
				</Grid>
				<Grid
					width="100%"
					px={4}
					py={2}
					backgroundColor={colors?.[backgroundColor] || backgroundColor}
					sx={{
						borderBottomLeftRadius: "10px",
						borderBottomRightRadius: "10px",
						fontSize: "1rem",
						justifyContent: "flex-end",
						display: "flex",
					}}
				>
					<ThirdBorderButton title="Cancel" backgroundColor="transparent" onClick={() => onDecline()} />
					<div style={{ width: "10px" }} />
					<ErrorBackgroundButton title={actionTitle} onClick={() => onAccept()} />
				</Grid>
			</Box>
		</Modal>
	);
};

export default memo(AreYouSurePopup);
