const bibtexTypeMatchingArray = [
    { bibtexValue: "article", value: "Article" },
    { bibtexValue: "book", value: "Book" },
    { bibtexValue: "booklet", value: "Book" },
    { bibtexValue: "conference", value: "Conference" },
    { bibtexValue: "inbook", value: "Book chapter" },
    { bibtexValue: "incollection", value: "Conference" },
    { bibtexValue: "inproceedings", value: "Conference" },
    { bibtexValue: "manual", value: "Misc" },
    { bibtexValue: "masterthesis", value: "Master thesis" },
    { bibtexValue: "misc", value: "Misc" },
    { bibtexValue: "phdthesis", value: "PHD thesis" },
    { bibtexValue: "proceedingns", value: "Conference" },
    { bibtexValue: "techreport", value: "Misc" },
    { bibtexValue: "unpublished", value: "Misc" },
];

const bibtexFieldsMatchingArray = [
    { bibtexValue: "type", value: "publicationType" },
    { bibtexValue: "title", value: "title" },
    { bibtexValue: "doi", value: "doi" },
    { bibtexValue: "author", value: "orderedAuthorsNames" },
    { bibtexValue: "keywords", value: "keywords" },
    { bibtexValue: "abstract", value: "abstract" },
    { bibtexValue: "url", value: "published_url" },
    { bibtexValue: "journal", value: "publishedTarget" },
    { bibtexValue: "booktitle", value: "publishedTarget" },
    { bibtexValue: "volume", value: "volume" },
    { bibtexValue: "number", value: "issue" },
    { bibtexValue: "pages", value: "pages" },
    { bibtexValue: "raw", value: "citation" },
    { bibtexValue: "year", value: "publicationDate" },
]

const createBibtexString = (publication) => {
    var addedPublishedTarget = false;
    var publicationExportObject = {}
    var publicationExportString = ''
    var exportId = ''
    for (var field of bibtexFieldsMatchingArray) {
        if (field.bibtexValue === 'raw' && publication[field.value]) {
            continue;
        }
        if (field.bibtexValue === 'type' && publication[field.value]) {
            const matchingType = bibtexTypeMatchingArray.find(item => item.value === publication.publicationType);
            const bibtexType = matchingType ? matchingType.bibtexValue : 'article';
            publicationExportObject[field.bibtexValue] = bibtexType;
            publicationExportString = '@' + bibtexType + '{,' + '\n'
            continue;
        }

        if (field.bibtexValue === 'year' && publication[field.value]) {
            const date = new Date(publication[field.value]);
            exportId = exportId + `${date.getUTCFullYear()}`
            publicationExportObject[field.bibtexValue] = '{' + `${date.getUTCFullYear()}` + '},';
            publicationExportString = publicationExportString + field.bibtexValue + '=' + publicationExportObject[field.bibtexValue] + '\n'
            continue;
        }

        if (field.bibtexValue === 'author' && publication[field.value]) {
            publicationExportObject[field.bibtexValue] = ''
            exportId = exportId + publication[field.value][0]
            for (var count in publication[field.value]) {
                if (count == publication[field.value].length - 1) {
                    publicationExportObject[field.bibtexValue] = publicationExportObject[field.bibtexValue] + publication[field.value][count];
                } else {
                    publicationExportObject[field.bibtexValue] = publicationExportObject[field.bibtexValue] + publication[field.value][count] + ' and '
                }
            }
            publicationExportObject[field.bibtexValue] = '{' + publicationExportObject[field.bibtexValue] + '},'
            publicationExportString = publicationExportString + field.bibtexValue + '=' + publicationExportObject[field.bibtexValue] + '\n'
            continue;
        }

        if ((field.bibtexValue === 'journal' || field.bibtexValue === 'booktitle')) {
            if (publication['publishedTarget'] && !addedPublishedTarget) {
                const type = publication.publicationType;
                const matchingType = bibtexTypeMatchingArray.find(item => item.value === type);
                const isConferenceOrBook = matchingType && ['conference', 'inproceedings', 'incollection', 'inbook', 'book'].includes(matchingType.bibtexValue);

                const bibtexField = isConferenceOrBook ? 'booktitle' : 'journal';
                publicationExportObject[bibtexField] = '{' + publication['publishedTarget'] + '},';
                publicationExportString = publicationExportString + bibtexField + '=' + publicationExportObject[bibtexField] + '\n'
                addedPublishedTarget = true;
            }
            continue;
        }

        if (field.bibtexValue === 'keywords' && publication[field.value] && publication[field.value].length == 0) {
            continue;
        }

        if (publication[field.value]) {
            publicationExportObject[field.bibtexValue] = '{' + publication[field.value] + '},';
            publicationExportString = publicationExportString + field.bibtexValue + '=' + publicationExportObject[field.bibtexValue] + '\n'
        }
    }

    publicationExportString = publicationExportString + '}'
    exportId = exportId.toUpperCase().replaceAll(" ", "");
    var randomFourDigitNumber = Math.floor(1000 + Math.random() * 9000);
    exportId = exportId + randomFourDigitNumber;
    exportId = exportId.replaceAll(',', '').replaceAll('.', '');
    publicationExportString = publicationExportString.replace("{,", `{${exportId},`)
    return [publicationExportString, exportId]
}

export default createBibtexString;