import { useParams } from "react-router-dom";
import { Grid, Typography, Link } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { PublicationsComponent } from "../components/PublicationsComponent.js";
import { memo, useCallback, useEffect, useState } from "react";
import Spinner from "../components/Spinner.js";
import PublicationFilters from "../components/PublicationFilters.js";
import { useSnackbar, dayjs } from "../utils/index.js";
import Popup from "../components/Popup.js";
import {
	getPublicTeam,
} from "../api/index.js";

const useStyles = makeStyles((theme) => ({
	link: {
		color: theme.palette.green.main,
		textDecoration: "underline",
		textDecorationColor: theme.palette.green.main,
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.green.dark,
		},
	},
}));

const TeamInfo = ({ content }) => {
	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [team, setTeam] = useState([]);

	const [filteredPublications, setFilteredPublications] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [pageContent, setPageContent] = useState(content);
	const [selectedPublication, setSelectedPublication] = useState(null);
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	const { teamid } = useParams();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				console.log(pageContent);
				const { success: scs, team: tm, message } = await getPublicTeam(teamid, pageContent);
				if (scs) {
					console.log(tm);
					// Sort publications by last updated
					tm.publications.sort((a, b) => dayjs(b.updatedAt).diff(dayjs(a.updatedAt)));
					// Sort teamNames by full-name
					tm.finalNames.sort((a, b) => {
						if (a.fullname < b.fullname) {
							return -1;
						}

						if (a.fullname > b.fullname) {
							return 1;
						}

						return 0;
					});
					// add team-name to each publication
					tm.publications = tm.publications.map((pub) => (
						{
							...pub,
							teamName: tm.name,
						}
					));

					setTeam(tm);
				} else {
					console.log(message);
					error(message ?? '');
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		[error, teamid],
	);

	// Open the popup when the selected publication changes
	useEffect(() => {
		if (selectedPublication) {
			setOpen(true);
		}
	}, [selectedPublication]);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					width="100%"
					xs={12}
					display="flex"
					flexDirection="column"
					sx={{
						borderRadius: "20px",
					}}
				>
					<Grid
						container
						item
						width="100%"
						p={1}
						pl={2}
						mb={1}
						xs={12}
						display="flex"
						flexDirection="row"
						borderRadius="20px"
						alignItems="center"
						justifyContent="space-between"
					>
						<Grid item>
							<Typography variant="h5" color="white.main">
								{"Documents"}
							</Typography>
						</Grid>
						<Grid item>
							<PublicationFilters
								teams={[]}
								publications={team.publications ?? []}
								setFilteredPublications={setFilteredPublications}
								showTeamFilter={false}
								showStatesFilter={false}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						xs={12}
						flexDirection="row"
						justifyContent="space-between"
					>
						<Grid
							item
							// xs={7}
							overflow='auto'
						>
							{
								team.publications?.length === 0 && (
									<Typography color="gray" pl={2} pb={1} pt={1}>
										{"No documents"}
									</Typography>
								)
							}
							<PublicationsComponent
								filteredPublications={filteredPublications ?? []}
								setSelectedPublication={setSelectedPublication}
								publicPreview="true"
								showTeam="false"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Popup
				width="1200px"
				open={open}
				title="Publication Information"
				backgroundColor="#fff"
				color="#000"
				onClose={() => {
					setSelectedPublication(null);
					setOpen(false);
				}}
			>
				{selectedPublication && (
					<Grid
						width="100%"
						p={1}
						mb={1}
						xs={12}
						display="flex"
						flexDirection="column"
						overflow='auto'
					>
						{/* Show title */}
						{selectedPublication.title && (
							<Typography mb={1} fontWeight="bold" color="green.main" textAlign="justify">
								{selectedPublication.title}
							</Typography>
						)}
						{/* Show authors */}
						<Grid
							item
							display="flex"
							flexDirection="row"
							mb={1}
						>
							<Typography fontSize="0.9rem" color="primary">
								{"Authors:"}
							</Typography>
							<Typography fontSize="0.9rem" color="primary">
								&nbsp;
							</Typography>
							<Typography fontStyle='italic' color="primary" fontSize="0.9rem" mb={1}>
								{
									selectedPublication.orderedAuthorsNames.map((author, innerInd) => (
										<span key={author}>
											{author}
											{innerInd !== selectedPublication.orderedAuthorsNames.length - 1 && ", "}
										</span>
									))
								}
							</Typography>
						</Grid>
						{/* Show publication date */}
						{selectedPublication.publicationDate && (
							<Typography fontSize="0.9rem" mb={1} color="primary">
								{`Published on ${dayjs(selectedPublication.publicationDate).format("DD-MM-YYYY")}`}
								{selectedPublication.publishedTarget && ` at ${selectedPublication.publishedTarget}`}
							</Typography>
						)}
						{/* Show doi */}
						{selectedPublication.doi && (
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignContent="center"
								mb={1}
							>
								<Typography fontSize="0.9rem" mr={1} color="primary">
									{"Doi: "}
								</Typography>
								<Link
									href={selectedPublication.doi}
									target="_blank"
									className={classes.link}
								>
									<Typography fontSize="0.9rem">
										{selectedPublication.doi}
									</Typography>
								</Link>
							</Grid>
						)}
						{/* Show doi */}
						{selectedPublication.published_url && (
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignContent="center"
								mb={1}
							>
								<Typography fontSize="0.9rem" mr={1} color="primary">
									{"URL: "}
								</Typography>
								<Link
									href={selectedPublication.published_url}
									target="_blank"
									className={classes.link}
								>
									<Typography fontSize="0.9rem">
										{selectedPublication.published_url}
									</Typography>
								</Link>
							</Grid>
						)}
						{/* Show code */}
						{selectedPublication.code && (
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignContent="center"
								mb={1}
							>
								<Typography fontSize="0.9rem" mr={1} color="primary">
									{"Code: "}
								</Typography>
								<Link
									href={selectedPublication.code}
									target="_blank"
									className={classes.link}
								>
									<Typography fontSize="0.9rem">
										{selectedPublication.code}
									</Typography>
								</Link>
							</Grid>
						)}
						{/* Show presentation */}
						{selectedPublication.presentation && (
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignContent="center"
								mb={1}
							>
								<Typography fontSize="0.9rem" mr={1} color="primary">
									{"Presentation: "}
								</Typography>
								<Link
									href={selectedPublication.presentation}
									target="_blank"
									className={classes.link}
								>
									<Typography fontSize="0.9rem">
										{selectedPublication.presentation}
									</Typography>
								</Link>
							</Grid>
						)}
						{/* Show keywords */}
						{selectedPublication.keywords.length > 0 && (
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignContent="center"
								mb={1}
							>
								<Typography fontSize="0.9rem" color="primary">
									{"Keywords:"}
								</Typography>
								<Typography fontSize="0.9rem" color="primary">
									&nbsp;
								</Typography>
								<Typography fontStyle='italic' fontSize="0.9rem" color="primary">
									{selectedPublication.keywords.map((keyword, innerInd) => (
										<span key={keyword}>
											{keyword}
											{innerInd !== selectedPublication.keywords.length - 1 && ", "}
										</span>
									))}
								</Typography>
							</Grid>
						)}
						{/* Bibtex */}
						{selectedPublication.citation && (
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignContent="center"
								mb={1}
							>
								<Typography fontSize="0.9rem" color="primary">
									{"Bibtex:"}
								</Typography>
								<Typography fontSize="0.9rem" color="primary">
									&nbsp;
								</Typography>
								<Typography
									pr={1.5}
									className={classes.link}
									onClick={() => {
										navigator.clipboard.writeText(selectedPublication.citation);
										success("Copied to clipboard");
									}}
								>
									<Typography fontSize="0.9rem">
										{"Copy to clipboard"}
									</Typography>
								</Typography>
							</Grid>
						)}
						{/* Show abstract */}
						{selectedPublication.abstract && (
							<Grid
								item
								display="flex"
								flexDirection="row"
								alignContent="center"
								mb={1}
							>
								<Typography fontSize="0.9rem" color="primary">
									{"Abstract:"}
								</Typography>
								<Typography fontSize="0.9rem" color="primary">
									&nbsp;
								</Typography>
								<Typography fontSize="0.9rem" color="primary" textAlign="justify">
									{selectedPublication.abstract}
								</Typography>
							</Grid>
						)}
					</Grid>
				)}
			</Popup>
		</>
	);
};

export default memo(TeamInfo);
