/* eslint-disable unicorn/consistent-destructuring */
import { useEffect, useState } from "react";
import {
	Typography,
	Link,
	Box,
	TextField,
	IconButton,
	Select,
	Checkbox,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUpload from "../FileUpload.js";
import Popup from "../Popup.js";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updatePublicationInfo, getTeam } from "../../api/index.js";
import DatePicker from "../DatePicker.js";
import { jwt } from "../../utils/index.js";
import { makeStyles } from "@mui/styles";
import { formatDate } from "../../utils/timestamps.js";
import { HighlightBackgroundButton } from "../Buttons.js";
import { publicationQuartiles } from "../../utils/publication-constants.js";
import MenuItem from '@mui/material/MenuItem';

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	input: {
		color: "black",
		width: "100%",
		backgroundColor: "white",
		opacity: 0.7,
		borderRadius: "4px",
		marginBottom: "10px",
		"&:hover": {
			opacity: 0.8,
		},
	},
	link: {
		color: theme.palette.third.main,
		textDecorationColor: theme.palette.third.main,
		textDecoration: "underline",
		"&:hover": {
			color: theme.palette.third.dark,
			textDecoration: "underline",
		},
	},
	icons: {
		color: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));

const PublicationSchema = Yup.object().shape({
	// title: Yup.string()
	// 	.required('Required'),
	// // artist: Yup.string()
	// // 	.required('Required'),
	// wikiLink: Yup.string(),
	// file: Yup.string()
	// 	.required('Required'),
	// image: Yup.string()
	// 	.required('Required'),
});

const titleWidth = "20%";
const fieldWidth = "80%";

const EditPublicationInfoPopup = ({
	publication,
	open,
	setOpen,
	fetchData,
	success,
	error,
	isLoading,
	setIsLoading,
}) => {
	const classes = useStyles();
	const [folder, setFolder] = useState(null);
	const [teamTags, setTeamTags] = useState([]);
	const updatePublicationSubmitHandler = async (values, resetForm) => {
		console.log(values);
		setIsLoading(true);
		const updatedPublication = values;
		updatedPublication.keywords = (updatedPublication.keywords === "" || updatedPublication.keywords === null) ? [] : updatedPublication.keywords.split(',');
		try {
			if (updatedPublication.publicationDate.includes('T')) {
				updatedPublication.publicationDate = formatDate(updatedPublication.publicationDate);
			}
		} catch { updatedPublication.publicationDate = undefined; }

		// updatedPublication.keywords = updatedPublication.keywords.forEach((key) => key.trim());
		try {
			const { success: successCode, message } = await updatePublicationInfo(publication._id, updatedPublication);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
		resetForm();
		setOpen(false);
	};

	const formik = useFormik({
		initialValues: {}, // initialized through setInitialValues!
		validationSchema: PublicationSchema,
		onSubmit: (values, { resetForm }) => updatePublicationSubmitHandler(values, resetForm),
	});

	const setPreprint = (originalName, savedName) => {
		formik.setFieldValue('preprintOriginalName', originalName);
		formik.setFieldValue('preprintSavedName', savedName);
	};

	const setCameraReady = (originalName, savedName) => {
		formik.setFieldValue('cameraReadyOriginalName', originalName);
		formik.setFieldValue('cameraReadySavedName', savedName);
	};

	const setInitialValues = (pub) => {
		// console.log("Initial values", pub);
		formik.setFieldValue('abstract', pub.abstract ?? undefined);
		formik.setFieldValue('keywords', pub.keywords ? pub.keywords.join(', ') : undefined);
		formik.setFieldValue('published_url', pub.published_url ?? undefined);
		formik.setFieldValue('publishedTarget', pub.publishedTarget ?? undefined);
		formik.setFieldValue('code', pub.code ?? undefined);
		formik.setFieldValue('presentation', pub.presentation ?? undefined);
		formik.setFieldValue('doi', pub.doi ?? undefined);
		formik.setFieldValue('volume', pub.volume ?? undefined);
		formik.setFieldValue('issue', pub.issue ?? undefined);
		formik.setFieldValue('pages', pub.pages ?? undefined);
		formik.setFieldValue('citation', pub.citation ?? undefined);
		formik.setFieldValue('publicationDate', pub.publicationDate ?? undefined);
		formik.setFieldValue('preprintOriginalName', pub.preprintOriginalName ?? undefined);
		formik.setFieldValue('preprintSavedName', pub.preprintSavedName ?? undefined);
		formik.setFieldValue('preprintFolder', pub._id ?? undefined);
		formik.setFieldValue('cameraReadyOriginalName', pub.cameraReadyOriginalName ?? undefined);
		formik.setFieldValue('cameraReadySavedName', pub.cameraReadySavedName ?? undefined);
		formik.setFieldValue('cameraReadyFolder', pub._id ?? undefined);
		formik.setFieldValue('impactFactor', pub.impactFactor ?? undefined);
		formik.setFieldValue('quartile', pub.quartile ?? "No quartile");
		formik.setFieldValue('tags', pub.tags ?? undefined);
	};

	useEffect(() => {
		if (open === true) {
			const pub = { ...publication };
			formik.initialValues = setInitialValues(pub);
			console.log("EDIT", publication);
			setFolder(publication._id);
			(async () => {
				try {
					const { success: successCode, team, message } = await getTeam(publication.team);
					if (successCode) {
						team.tags.sort((a, b) => {
							if (a.toLowerCase() < b.toLowerCase()) {
								return -1;
							}

							if (a.toLowerCase() > b.toLowerCase()) {
								return 1;
							}

							return 0;
						});
						setTeamTags(team.tags);
					} else {
						error(message);
					}
				} catch { /* empty */ }
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, publication]);

	const { errors, touched, dirty } = formik;
	const disableSubmit = !dirty; // isLoading || errors;

	return (
		<Popup
			width="80%"
			open={open}
			title="Edit publication info"
			onClose={() => {
				formik.resetForm();
				setOpen(false);
			}}
		>
			<form onSubmit={formik.handleSubmit}>
				<Box
					sx={{ mt: 2 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width={titleWidth}
						color="third.main"
					>
						{"Published at:"}
					</Box>
					<Box
						width={fieldWidth}
					>
						<TextField
							fullWidth
							id="pubolishedTarget"
							name="publishedTarget"
							type="text"
							variant="filled"
							color="primary"
							className={classes.input}
							value={formik.values.publishedTarget}
							error={errors.publishedTarget && touched.publishedTarget}
							helperText={errors.publishedTarget && touched.publishedTarget ? errors.publishedTarget : ""}
							disabled={isLoading}
							onChange={formik.handleChange}
						/>
					</Box>
				</Box>
				<Box
					sx={{ mt: 2 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width={titleWidth}
						color="third.main"
					>
						{"Abstract:"}
					</Box>
					<Box
						width={fieldWidth}
					>
						<TextField
							fullWidth
							multiline
							id="abstract"
							name="abstract"
							type="text"
							variant="filled"
							color="primary"
							className={classes.input}
							rows={8}
							value={formik.values.abstract}
							error={errors.abstract && touched.abstract}
							helperText={errors.abstract && touched.abstract ? errors.abstract : ""}
							disabled={isLoading}
							onChange={formik.handleChange}
						/>
					</Box>
				</Box>
				{publication.metaType === "publication" && (
					<Box
						sx={{ mt: 2 }}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Box
							width={titleWidth}
							color="third.main"
						>
							{"Keywords:"}
						</Box>
						<Box
							width={fieldWidth}
						>
							<TextField
								fullWidth
								id="keywords"
								name="keywords"
								type="text"
								variant="filled"
								color="primary"
								className={classes.input}
								value={formik.values.keywords}
								error={errors.keywords && touched.keywords}
								helperText={errors.keywords && touched.keywords ? errors.keywords : "Comma separated values"}
								disabled={isLoading}
								onChange={formik.handleChange}
							/>
						</Box>
					</Box>
				)}

				<Box
					sx={{ mt: 2 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width={titleWidth}
						color="third.main"
					>
						{"URL:"}
					</Box>
					<Box
						width={fieldWidth}
					>
						<TextField
							fullWidth
							id="published_url"
							name="published_url"
							type="text"
							variant="filled"
							color="primary"
							className={classes.input}
							value={formik.values.published_url}
							error={errors.published_url && touched.published_url}
							helperText={errors.published_url && touched.published_url ? errors.published_url : ""}
							disabled={isLoading}
							onChange={formik.handleChange}
						/>
					</Box>
				</Box>

				<Box
					sx={{ mt: 2 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width={titleWidth}
						color="third.main"
					>
						{"Volume:"}
					</Box>
					<Box
						width={fieldWidth}
					>
						<TextField
							fullWidth
							id="volume"
							name="volume"
							type="text"
							variant="filled"
							color="primary"
							className={classes.input}
							value={formik.values.volume}
							error={errors.volume && touched.volume}
							helperText={errors.volume && touched.volume ? errors.volume : ""}
							disabled={isLoading}
							onChange={formik.handleChange}
						/>
					</Box>
				</Box>

				<Box
					sx={{ mt: 2 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width={titleWidth}
						color="third.main"
					>
						{"Issue:"}
					</Box>
					<Box
						width={fieldWidth}
					>
						<TextField
							fullWidth
							id="issue"
							name="issue"
							type="text"
							variant="filled"
							color="primary"
							className={classes.input}
							value={formik.values.issue}
							error={errors.issue && touched.issue}
							helperText={errors.issue && touched.issue ? errors.issue : ""}
							disabled={isLoading}
							onChange={formik.handleChange}
						/>
					</Box>
				</Box>

				<Box
					sx={{ mt: 2 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width={titleWidth}
						color="third.main"
					>
						{"Pages:"}
					</Box>
					<Box
						width={fieldWidth}
					>
						<TextField
							fullWidth
							id="pages"
							name="pages"
							type="text"
							variant="filled"
							color="primary"
							className={classes.input}
							value={formik.values.pages}
							error={errors.pages && touched.pages}
							helperText={errors.pages && touched.pages ? errors.pages : ""}
							disabled={isLoading}
							onChange={formik.handleChange}
						/>
					</Box>
				</Box>

				<Box
					sx={{ mt: 2 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width={titleWidth}
						color="third.main"
					>
						{"Code:"}
					</Box>
					<Box
						width={fieldWidth}
					>
						<TextField
							fullWidth
							id="code"
							name="code"
							type="text"
							variant="filled"
							color="primary"
							className={classes.input}
							value={formik.values.code}
							error={errors.code && touched.code}
							helperText={errors.code && touched.code ? errors.code : ""}
							disabled={isLoading}
							onChange={formik.handleChange}
						/>
					</Box>
				</Box>

				<Box
					sx={{ mt: 2 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width={titleWidth}
						color="third.main"
					>
						{"Presentation:"}
					</Box>
					<Box
						width={fieldWidth}
					>
						<TextField
							fullWidth
							id="presentation"
							name="presentation"
							type="text"
							variant="filled"
							color="primary"
							className={classes.input}
							value={formik.values.presentation}
							error={errors.presentation && touched.presentation}
							helperText={errors.presentation && touched.presentation ? errors.presentation : ""}
							disabled={isLoading}
							onChange={formik.handleChange}
						/>
					</Box>
				</Box>
				{publication.metaType === "publication" && (
					<Box
						sx={{ mt: 2 }}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Box
							width={titleWidth}
							color="third.main"
						>
							{"Doi:"}
						</Box>
						<Box
							width={fieldWidth}
						>
							<TextField
								fullWidth
								id="doi"
								name="doi"
								type="text"
								variant="filled"
								color="primary"
								className={classes.input}
								value={formik.values.doi}
								error={errors.doi && touched.doi}
								helperText={errors.doi && touched.doi ? errors.doi : ""}
								disabled={isLoading}
								onChange={formik.handleChange}
							/>
						</Box>
					</Box>
				)}
				{publication.metaType === "publication" && (
					<Box
						sx={{ mt: 2 }}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Box
							width={titleWidth}
							color="third.main"
						>
							{"Citation:"}
						</Box>
						<Box
							width={fieldWidth}
						>
							<TextField
								fullWidth
								multiline
								id="citation"
								name="citation"
								type="text"
								variant="filled"
								color="primary"
								rows={8}
								className={classes.input}
								value={formik.values.citation}
								error={errors.citation && touched.citation}
								helperText={errors.citation && touched.citation ? errors.citation : ""}
								disabled={isLoading}
								onChange={formik.handleChange}
							/>
						</Box>
					</Box>
				)}
				{publication.metaType === "publication" && (
					<Box
						sx={{ mt: 2 }}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Box
							width={titleWidth}
							color="third.main"
						>
							{"Impact Factor:"}
						</Box>
						<Box
							width={fieldWidth}
						>
							<TextField
								fullWidth
								id="impactFactor"
								name="impactFactor"
								type="number"
								variant="filled"
								color="primary"
								className={classes.input}
								value={formik.values.impactFactor}
								error={errors.impactFactor && touched.impactFactor}
								helperText={errors.impactFactor && touched.impactFactor ? errors.impactFactor : ""}
								disabled={isLoading}
								onChange={formik.handleChange}
							/>
						</Box>
					</Box>
				)}
				{publication.metaType === "publication" && (
					<Box
						sx={{ mt: 2 }}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Box
							width={titleWidth}
							color="third.main"
						>
							{"Quartile:"}
						</Box>
						<Box
							width={fieldWidth}
						>
							<Select
								fullWidth
								id="quartile"
								name="quartile"
								color="primary"
								className={classes.input}
								value={formik.values.quartile}
								error={errors.quartile && touched.quartile}
								disabled={isLoading}
								onChange={formik.handleChange}
							>
								{
									publicationQuartiles.slice(1).map((e, ind) => (
										<MenuItem key={ind} value={e.value} className={classes.input}>{e.text}</MenuItem>
									))
								}
							</Select>
						</Box>
					</Box>
				)}
				{(publication.metaType === "publication" || publication.metaType === "master_thesis" || publication.metaType === "phd_thesis") && (
					<Box
						sx={{ mt: 2 }}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Box
							width={titleWidth}
							color="third.main"
						>
							{"Tags:"}
						</Box>
						<Box
							width={fieldWidth}
						>
							{(teamTags.length > 0) ? (
								<Select
									fullWidth
									multiple
									id="tags"
									name="tags"
									color="primary"
									className={classes.input}
									value={formik.values.tags || []}
									error={errors.tags && touched.tags}
									disabled={isLoading}
									onChange={formik.handleChange}
								>
									{
										teamTags.map((e, ind) => (
											<MenuItem key={ind} value={e} className={classes.input}>
												<Checkbox checked={formik.values.tags?.indexOf(e) > -1} />
												{e}
											</MenuItem>
										))
									}
								</Select>

							) : (
								<Typography sx={{
									color: "gray",
								}}
								>
									{"Your team has no tags"}
								</Typography>
							)}
						</Box>
					</Box>
				)}
				<Box
					sx={{ mt: 2 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width={titleWidth}
						color="third.main"
					>
						{"Publication Date:"}
					</Box>
					<Box
						width="200px"
					>
						<DatePicker
							type="desktop"
							label=""
							background="primary"
							color="third"
							value={formik.values.publicationDate}
							onChange={(value) => {
								// console.log(`Date changed to ${value}`);
								// console.log(typeof value);
								try {
									console.log(value);
									console.log(`${value.$D}/${value.$M + 1}/${value.$y}`);
									formik.setFieldValue('publicationDate', `${value.$D}/${value.$M + 1}/${value.$y}`);
								} catch (error_) { console.log(error_); }
							}}
						/>
					</Box>
				</Box>
				{publication.metaType === "publication" && (
					<Box
						sx={{ mt: 2 }}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Box width={titleWidth} color="third.main">{"Preprint file:"}</Box>
						<FileUpload
							id="file-upload-review"
							folder={folder}
							color="third"
							component="button"
							onSuccess={({ originalName, saveName }) => {
								setPreprint(originalName, saveName);
							}}
						// onSuccess={cameraReadyUploadSuccess}
						/>
						<Box sx={{ ml: 2, width: "50%" }}>
							{formik.values.preprintSavedName ? (
								<Link
									fontSize="0.9rem"
									sx={{
										mr: 1,
										pt: 0.5,
									}}
									href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${folder}/${formik.values.preprintSavedName}?token=${jwt.getToken()}`}
									target="_blank"
									className={classes.link}
								>
									{`${formik.values.preprintOriginalName}`}
								</Link>
							) : (
								<Typography fontSize="0.9rem" color="gray" pr={1.5}>
									{"No preprint file"}
								</Typography>
							)}
						</Box>
						<Box sx={{ ml: 2 }}>
							<IconButton
								onClick={() => {
									formik.setFieldValue('preprintOriginalName', '');
									formik.setFieldValue('preprintSavedName', '');
								}}
							>
								<DeleteIcon className={classes.icons} />
							</IconButton>
						</Box>
					</Box>
				)}
				{publication.metaType === "publication" && (
					<Box
						sx={{ mt: 2 }}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Box width={titleWidth} color="third.main">{"Camera-ready file:"}</Box>
						<FileUpload
							id="file-upload-review2"
							folder={folder}
							color="third"
							component="button"
							onSuccess={({ originalName, saveName }) => {
								// console.log("Setting camera ready!", originalName, saveName);
								setCameraReady(originalName, saveName);
							}}
						// onSuccess={cameraReadyUploadSuccess}
						/>
						<Box sx={{ ml: 2, width: "50%" }}>
							{formik.values.cameraReadySavedName ? (
								<Link
									fontSize="0.9rem"
									sx={{
										mr: 1,
										pt: 0.5,
									}}
									href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${folder}/${formik.values.cameraReadySavedName}?token=${jwt.getToken()}`}
									target="_blank"
									className={classes.link}
								>
									{`${formik.values.cameraReadyOriginalName}`}
								</Link>
							) : (
								<Typography fontSize="0.9rem" color="gray" pr={1.5}>
									{"No camera-ready file"}
								</Typography>
							)}
						</Box>
						<Box sx={{ ml: 2 }}>
							<IconButton
								onClick={() => {
									formik.setFieldValue('cameraReadyOriginalName', '');
									formik.setFieldValue('cameraReadySavedName', '');
								}}
							>
								<DeleteIcon className={classes.icons} />
							</IconButton>
						</Box>
					</Box>
				)}
				<Box
					sx={{ mt: 4 }}
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="center"
					width="100%"
				>
					<HighlightBackgroundButton
						title="Save publication info"
						disabled={disableSubmit}
						type="submit"
					/>
				</Box>
			</form>
		</Popup>
	);
};

export default EditPublicationInfoPopup;
