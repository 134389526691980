import { memo, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const notEditable = new Set(["createdAt"]);

const useStyles = makeStyles((theme) => ({
	editIcon: {
		color: theme.palette.third.main,
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));

/**
 * Renders a project information element.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.project - The project object.
 * @param {string} props.title - The title of the information element.
 * @param {string} props.infoKey - The key of the information value in the project object.
 * @param {string} [props.postfix=""] - The postfix to be added to the information value.
 * @param {string} [props.infoType="text"] - The type of the information value.
 * @param {string} [props.textSize="0.9rem"] - The font size of the text.
 * @returns {JSX.Element} The rendered project information element.
 */
const ProjectInformationElement = ({
	project,
	title,
	infoKey,
	postfix = "",
	infoType = "text",
	textSize = "0.9rem",
	setPopupOpen,
	setCurrentKey,
	setCurrentTitle,
}) => {
	const [value, setValue] = useState("");
	const classes = useStyles();

	useEffect(() => {
		setValue(project[infoKey]);
		if (infoType === "date") {
			if (project[infoKey] === undefined || project[infoKey] === null) {
				setValue();
			} else setValue(new Date(project[infoKey]).toLocaleDateString());
		} else if (infoType === "boolean") {
			setValue(project[infoKey] ? "Yes" : "No");
		} else if (project[infoKey] && postfix === "€") {
			setValue(project[infoKey].toFixed(2));
		} else if (infoType === "checkboxes-dropdown") {
			if (project?.[infoKey]?.length > 0) {
				if (infoKey === 'tags') {
					project[infoKey].sort((a, b) => {
						if (a.toLowerCase() < b.toLowerCase()) {
							return -1;
						}

						if (a.toLowerCase() > b.toLowerCase()) {
							return 1;
						}

						return 0;
					});
				}

				setValue(project[infoKey].join(", "));
			} else {
				setValue();
			}
		}
	}, [infoKey, infoType, postfix, project]);

	return (
		<Grid
			item
			container
			width="100%"
			mt={0.5}
			flexDirection="row"
			style={{ alignItems: "center" }}
		>
			{notEditable.has(infoKey) || setPopupOpen === undefined ? null : (
				<Grid>
					<DriveFileRenameOutlineIcon
						className={classes.editIcon}
						fontSize="small"
						onClick={() => {
							if (notEditable.has(infoKey)) return;
							setCurrentKey(infoKey);
							setCurrentTitle(title);
							setPopupOpen(true);
						}}
					/>
				</Grid>
			)}
			{!(value === undefined && setPopupOpen === undefined) && (
				<>
					<Typography
						mr={1}
						ml={notEditable.has(infoKey) || setPopupOpen === undefined ? 0 : 1}
						sx={{
							color: "white.main",
							fontSize: textSize,
						}}
					>
						{`${title}:`}
					</Typography>
					<Typography
						sx={{
							color: project[infoKey] === undefined || project[infoKey] === null ? "gray" : "green.main",
							fontSize: textSize,
						}}
					>
						{value === undefined ? "Not declared" : value + postfix}
					</Typography>
				</>
			)}

		</Grid>
	);
};

export default memo(ProjectInformationElement);
