import { useState } from 'react';
import { Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";
import Popup from "../Popup.js";
import Form from "../Form.js";
import createBibtexString from '../../utils/create-bibtex-string.js';

const ExportSelectPublicationsPopup = ({
	open,
	setOpen,
	publications,
}) => {
	const [selectedPublications, setSelectedPublications] = useState([]);
	const [selectAll, setSelectAll] = useState(false);

	const handleCheckboxChange = (pubId) => {
		setSelectedPublications((prevSelected) => {
			if (prevSelected.includes(pubId)) {
				return prevSelected.filter((id) => id !== pubId);
			}

			return [...prevSelected, pubId];
		});
	};

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedPublications([]);
		} else {
			const allPublicationIds = publications.map((pub) => pub._id);
			setSelectedPublications(allPublicationIds);
		}

		setSelectAll(!selectAll);
	};

	const exportPublicationsFormContent = [
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Export selected Publications",
		},
	];

	const exportPublicationsSubmitHandler = () => {
		let exportString = '';
		for (const selectedPublication of selectedPublications) {
			const pubObject = publications.find((pub) => pub._id === selectedPublication);
			const bibString = createBibtexString(pubObject);
			const publicationExportString = bibString[0];
			exportString += `${publicationExportString}\n\n`;
		}

		const fileId = Date.now();
		const content = `${exportString}`;
		const blob = new Blob([content], { type: 'text/plain' });
		const link = document.createElement("a");
		const url = URL.createObjectURL(blob);
		link.href = url;
		link.download = `${fileId}.bib`;
		document.body.append(link);
		link.click();
		link.remove();

		setOpen(false);
		setSelectedPublications([]);
		setSelectAll(false);
	};

	return (
		<Popup
			width="800px"
			open={open}
			title="Select Publications to export"
			onClose={() => {
				setOpen(false);
				setSelectedPublications([]);
				setSelectAll(false);
			}}
		>
			<Grid container flexDirection="column">

				{publications.length > 0
					? (
						<>
							<Grid item>
								<FormControlLabel
									control={(
										<Checkbox
											color="light"
											checked={selectAll}
											onChange={handleSelectAllChange}
										/>
									)}
									label={(
										<Typography
											style={{
												color: "white",
												fontSize: "16px",
												fontWeight: "bold",
											}}
										>
											{"Select All"}
										</Typography>
									)}
								/>
							</Grid>
							{publications.map((pub, index) => (
								<Grid key={index} item container alignItems="center" sx={{ marginBottom: "1rem" }}>
									<FormControlLabel
										control={(
											<Checkbox
												color="light"
												checked={selectedPublications.includes(pub._id)}
												onChange={() => handleCheckboxChange(pub._id)}
											/>
										)}
										label={(
											<Typography
												style={{
													color: "white",
													fontSize: "16px",
													fontWeight: "bold",
												}}
											>
												{pub.title}
											</Typography>
										)}
									/>
								</Grid>
							))}
							<Form
								content={exportPublicationsFormContent}
								onSubmit={exportPublicationsSubmitHandler}
							/>
						</>
					) : (
						<Grid container justifyContent="center" alignItems="center">
							<Typography
								style={{
									color: "grey",
									fontSize: "18px",
								}}
							>
								{"No publications to export"}
							</Typography>
						</Grid>
					)}
			</Grid>
		</Popup>
	);
};

export default ExportSelectPublicationsPopup;
