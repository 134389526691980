import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	Link,
	Chip,
	Menu,
	MenuItem,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { jwt } from "../../utils/index.js";
import { formatDate } from "../../utils/timestamps.js";
import EditPublicationInfoPopup from "../Popups/EditPublicationInfoPopup.js";
import { HighlightBackgroundButton } from "../Buttons.js";
import { ImportFilePopup } from "../Popups/index.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	keywordChip: {
		backgroundColor: theme.palette.third.main,
		"& .MuiChip-label": {
			color: theme.palette.primary.main,
		},
	},
	link: {
		color: theme.palette.green.main,
		textDecoration: "underline",
		textDecorationColor: theme.palette.green.main,
		"&:hover": {
			color: theme.palette.green.dark,
		},
	},
}));

const Publication = ({
	setIsLoading,
	isLoading,
	publication,
	setAbstractShowDialog,
	success,
	error,
	fetchData,
}) => {
	const [openEditPublicationInfoPopup, setOpenEditPublicationInfoPopup] = useState(false);
	const [openImportFilePopup, setOpenImportFilePopup] = useState(false);
	const [pasteTextPopupOpen, setPasteTextPopupOpen] = useState(false);
	const [bibtexContentOpen, setBibtexContentOpen] = useState(false);
	const [anchorElEditPublication, setAnchorElEditPublication] = useState(null);

	const handleClickEditPublication = (event) => {
		setAnchorElEditPublication(event.currentTarget);
	};

	const handleCloseEditPublicaiton = () => {
		setAnchorElEditPublication(null);
	};

	const newClasses = useStyles();

	useEffect(() => {}, []);

	return (
		<List dense>
			<EditPublicationInfoPopup
				open={openEditPublicationInfoPopup}
				setOpen={setOpenEditPublicationInfoPopup}
				publication={publication}
				success={success}
				error={error}
				fetchData={fetchData}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
			/>
			<ImportFilePopup
				isUpdate
				open={bibtexContentOpen}
				setOpen={setBibtexContentOpen}
				setIsLoading={setIsLoading}
				success={success}
				error={error}
				fetchData={fetchData}
				publication={publication}
				isImport={openImportFilePopup}
				isPaste={pasteTextPopupOpen}
				setImportFilePopupOpen={setOpenImportFilePopup}
				setPasteTextFilePopupOpen={setPasteTextPopupOpen}
			/>

			<Menu
				id="add-publication-menu"
				anchorEl={anchorElEditPublication}
				open={Boolean(anchorElEditPublication)}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				onClose={handleCloseEditPublicaiton}
			>
				<MenuItem
					onClick={() => {
						handleCloseEditPublicaiton();
						setOpenEditPublicationInfoPopup(true);
					}}
				>
					{"Edit publication info"}
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleCloseEditPublicaiton();
						setOpenImportFilePopup(true);
						setBibtexContentOpen(true);
					}}
				>
					{"Import BibTeX file"}
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleCloseEditPublicaiton();
						setPasteTextPopupOpen(true);
						setBibtexContentOpen(true);
					}}
				>
					{"Paste BibTeX text"}
				</MenuItem>
			</Menu>
			<ListItem
				sx={{
					pt: 1,
				}}
			>
				<ListItemAvatar>
					<Avatar sx={{ bgcolor: "third.main" }}>
						<ThumbUpIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={(
						<>
							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Target:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									<Typography fontSize="0.9rem" color={publication.publishedTarget ? "green.main" : "gray"} pr={1.5}>
										{
											publication.publishedTarget ?? "No publication target"
										}
									</Typography>
								</Grid>
							)}

							<Grid container style={{ alignItems: "baseline" }}>
								<Typography fontSize="0.9rem" color="#ffffff">
									{"Abstract:"}
								</Typography>
								<Typography fontSize="0.9rem" color="#ffffff">
									&nbsp;
								</Typography>
								<Typography
									fontSize="0.9rem"
									color={publication.abstract ? "green.main" : "gray"}
									sx={{
										textDecoration: publication.abstract ? "underline" : "",
										textDecorationColor: "green.main",
										cursor: publication.abstract ? "pointer" : "",
									}}
									pr={1.5}
									onClick={() => setAbstractShowDialog(Boolean(publication.abstract))}
								>
									{
										publication.abstract
											? `${publication.abstract.slice(0, 20)}
										${publication.abstract.length > 20 ? "..." : ""}`
											: "No abstract"
									}
								</Typography>
							</Grid>

							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Volume:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									<Typography fontSize="0.9rem" color={publication.volume ? "green.main" : "gray"} pr={1.5}>
										{
											publication.volume ?? "No volume"
										}
									</Typography>
								</Grid>
							)}
							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Issue:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									<Typography fontSize="0.9rem" color={publication.issue ? "green.main" : "gray"} pr={1.5}>
										{
											publication.issue ?? "No issue"
										}
									</Typography>
								</Grid>
							)}
							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Pages:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									<Typography fontSize="0.9rem" color={publication.pages ? "green.main" : "gray"} pr={1.5}>
										{
											publication.pages ?? "No pages"
										}
									</Typography>
								</Grid>
							)}

							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Keywords:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									{publication.keywords.length === 0 ? (
										<Typography fontSize="0.9rem" color="gray" pr={1.5}>
											{"No keywords"}
										</Typography>
									) : (
										publication.keywords.map((keyword, index) => (
											<Chip
												key={index}
												label={keyword}
												className={newClasses.keywordChip}
												size="small"
												variant="outlined"
												sx={{
													mr: 0.5,
													mt: 0.2,
												}}
											/>
										))
									)}
								</Grid>
							)}

							<Grid container style={{ alignItems: "baseline" }}>
								<Typography fontSize="0.9rem" color="#ffffff">
									{"URL:"}
								</Typography>
								<Typography fontSize="0.9rem" color="#ffffff">
									&nbsp;
								</Typography>
								<Typography fontSize="0.9rem" pr={1.5} color={publication.published_url ? "green.main" : "gray"}>
									{
										publication.published_url
											? (
												<Link href={publication.published_url} target="_blank" rel="noopener" className={newClasses.link}>
													{"Published URL"}
												</Link>
											)
											: "No URL"
									}
								</Typography>
							</Grid>

							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Citation:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									<Typography
										fontSize="0.9rem"
										pr={1.5}
										color={publication.citation ? "green.main" : "gray"}
										onClick={() => {
											navigator.clipboard.writeText(publication.citation);
											success("Copied to clipboard");
										}}
									>
										{
											publication.citation
												? (
													// eslint-disable-next-line jsx-a11y/anchor-is-valid
													<Link className={newClasses.link}>
														{"Copy citation"}
													</Link>
												)
												: "No citation"
										}
									</Typography>
								</Grid>
							)}

							<Grid container style={{ alignItems: "baseline" }}>
								<Typography fontSize="0.9rem" color="#ffffff">
									{publication.metaType === "publication" ? "Publication date:" : "Finished date:"}
								</Typography>
								<Typography fontSize="0.9rem" color="#ffffff">
									&nbsp;
								</Typography>
								<Typography
									fontSize="0.9rem"
									pr={1.5}
									color={publication.publicationDate ? "green.main" : "gray"}
								>
									{
										publication.publicationDate ? formatDate(publication.publicationDate) : "No date"
									}
								</Typography>
							</Grid>
							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Preprint file:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									{publication.preprintSavedName ? (
										<Link
											fontSize="0.9rem"
											sx={{
												mr: 1,
												pt: 0.5,
											}}
											href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${publication.preprintFolder}/${publication.preprintSavedName}?token=${jwt.getToken()}`}
											target="_blank"
											className={newClasses.link}
										>
											{`${publication.preprintOriginalName}`}
										</Link>
									) : (
										<Typography fontSize="0.9rem" color="gray" pr={1.5}>
											{"No preprint file"}
										</Typography>
									)}
								</Grid>
							)}
							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Camera-ready file:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									{publication.cameraReadySavedName ? (
										<Link
											fontSize="0.9rem"
											sx={{
												mr: 1,
												pt: 0.5,
											}}
											href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${publication.cameraReadyFolder}/${publication.cameraReadySavedName}?token=${jwt.getToken()}`}
											target="_blank"
											className={newClasses.link}
										>
											{`${publication.cameraReadyOriginalName}`}
										</Link>
									) : (
										<Typography fontSize="0.9rem" color="gray" pr={1.5}>
											{"No camera-ready file"}
										</Typography>
									)}
								</Grid>
							)}
							{publication.metaType === "publication" && (
								<Grid
									container
									style={{ alignItems: "baseline" }}
								>
									<Typography fontSize="0.9rem" color="#ffffff" mr={1}>
										{"Doi:"}
									</Typography>
									{publication.doi ? (
										<Link
											href={publication.doi}
											target="_blank"
										>
											<Typography
												fontSize="0.9rem"
												color={publication.publicationDate ? "green.main" : "gray"}
												title={publication.doi}
												sx={{ width: "90%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
											>
												{publication.doi}
											</Typography>
										</Link>
									) : (
										<Typography fontSize="0.9rem" color="gray">
											{"No doi declared"}
										</Typography>
									)}
								</Grid>
							)}
							<Grid container style={{ alignItems: "baseline" }}>
								<Typography fontSize="0.9rem" color="#ffffff">
									{"Code:"}
								</Typography>
								<Typography fontSize="0.9rem" color="#ffffff">
									&nbsp;
								</Typography>
								<Typography fontSize="0.9rem" pr={1.5} color={publication.code ? "green.main" : "gray"}>
									{
										publication.code
											? (
												<Link href={publication.code} target="_blank" rel="noopener" className={newClasses.link}>
													{"Code"}
												</Link>
											)
											: "No code"
									}
								</Typography>
							</Grid>
							<Grid container style={{ alignItems: "baseline" }}>
								<Typography fontSize="0.9rem" color="#ffffff">
									{"Presentation:"}
								</Typography>
								<Typography fontSize="0.9rem" color="#ffffff">
									&nbsp;
								</Typography>
								<Typography fontSize="0.9rem" pr={1.5} color={publication.presentation ? "green.main" : "gray"}>
									{
										publication.presentation
											? (
												<Link href={publication.presentation} target="_blank" rel="noopener" className={newClasses.link}>
													{"Presentation"}
												</Link>
											)
											: "No presentation"
									}
								</Typography>
							</Grid>
							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Impact Factor:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									<Typography fontSize="0.9rem" color={publication.impactFactor ? "green.main" : "gray"} pr={1.5}>
										{
											publication.impactFactor ?? "No impact factor"
										}
									</Typography>
								</Grid>
							)}
							{publication.metaType === "publication" && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Quartile:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									<Typography fontSize="0.9rem" color={publication.quartile && publication.quartile !== "No quartile" ? "green.main" : "gray"} pr={1.5}>
										{
											publication.quartile ?? "No quartile"
										}
									</Typography>
								</Grid>
							)}
							{(publication.metaType === "publication" || publication.metaType === "master_thesis" || publication.metaType === "phd_thesis") && (
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Tags:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									{publication.tags.length === 0 ? (
										<Typography fontSize="0.9rem" color="gray" pr={1.5}>
											{"No tags"}
										</Typography>
									) : (
										publication.tags.map((tag, index) => (
											<Chip
												key={index}
												label={tag}
												className={newClasses.keywordChip}
												size="small"
												variant="outlined"
												sx={{
													mr: 0.5,
													mt: 0.2,
												}}
											/>
										))
									)}
								</Grid>
							)}
						</>
					)}
				/>
			</ListItem>
			<ListItem sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
				<HighlightBackgroundButton
					title="Edit"
					onClick={publication.metaType === "publication"
						? (event) => handleClickEditPublication(event)
						: () => setOpenEditPublicationInfoPopup(true)}
				/>
			</ListItem>
		</List>
	);
};

export default Publication;
