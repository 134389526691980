import ky from "ky";
import queryString from "query-string";

import { jwt } from "../utils/index.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const rootApi = ky.extend({
	timeout: false,
	prefixUrl: `${REACT_APP_MAIN_SERVER_URL}/api`,
	retry: {
		statusCodes: [401, 408, 413, 429, 502, 503, 504],
		limit: 2,
		methods: ["get", "post", "put", "head", "delete", "options", "trace"],
	},
	hooks: {
		beforeRequest: [({ headers }) => {
			headers.set("x-access-token", jwt.getToken());
		}],
		beforeRetry: [
			async ({ request: { method }, error }) => {
				if (error?.response?.status === 401) {
					const res = await rootApi.extend({ throwHttpErrors: false, retry: 0 }).get("api/refresh");
					if (res.status === 401) {
						jwt.destroyToken();
						window.location.href = "/";
					} else {
						const { token } = await res.json();
						jwt.setToken(token);
					}
				} else if (method === "POST") {
					throw error;
				}
			},
		],
		afterResponse: [
			(_req, _opts, res) => {
				const { status } = res;
				if (status === 500) {
					return new Response(JSON.stringify({ success: false }), { status: 200 });
				}

				if (status === 404) {
					window.location.href = "/";
				}

				return res;
			},
		],
	},
});

const api = {
	get: (path, searchParams) => rootApi.get(path, { searchParams: queryString.stringify(searchParams) }).json(),
	post: (path, json) => rootApi.post(path, { json }).json(),
	put: (path, json) => rootApi.put(path, { json }).json(),
	patch: (path, json) => rootApi.patch(path, { json }).json(),
	delete: (path, json) => rootApi.delete(path, { json }).json(),
};

export default api;

// Basic user auth calls
export const authenticate = (username, password) => api.post("authenticate", { username, password });
export const authenticateGoogle = (token) => api.post("authenticateGoogle", { token });
export const forgotPassword = (username) => api.post("forgotPassword", { username });
export const resetPassword = (password, token) => api.post("resetPassword", { password, token });
export const signUp = (username, fullname, email, password) => api.post("createUser", { username, fullname, email, password });
export const invitedSignUp = (username, fullname, email, password, token) => api.post("createUserInvited", { username, fullname, email, password, token });

// Files calls
export const uploadFile = (body) => rootApi.post("file/", { body }).json();
export const reUploadFile = (body) => rootApi.put("file/", { body }).json();
export const deleteFile = (info) => api.post("file/delete/", info);

// User calls
export const inviteUser = (email) => api.post("user", { email });
export const removeUser = (id) => api.post("user/delete", { id });
export const getUsersData = () => api.get("user");
export const getUserPublications = (id) => api.get(`user/${id}/publications`);
export const getUserAssignments = (id) => api.get(`user/${id}/assignments`);
export const saveMarkdownTheme = (id, theme) => api.put(`user/${id}/saveMarkdownTheme`, { theme });
export const getMarkdownTheme = (id) => api.get(`user/${id}/markdownTheme`);

// Team calls
export const getTeamsData = () => api.get("team");
export const createTeam = (name, description, website, logo, discordURL) => api.post("team", { name, description, website, logo, discordURL });
export const updateTeam = (id, name, description, website, logo, discordURL, cycloptToken) => api.put(`team/${id}`, { name, description, website, logo, discordURL, cycloptToken });
export const removeTeam = (id) => api.post("team/delete", { id });
export const getTeam = (id) => api.get(`team/${id}`);
export const getTeamStatistics = (id) => api.get(`team/${id}/stats`);
export const getTeamMinimalInfo = (id) => api.get(`team/${id}/minimal`);
export const getTeamMembers = (id) => api.get(`team/${id}/members`);
export const getTeamProjects = (id) => api.get(`team/${id}/projects`);
export const getTeamEvents = (id) => api.get(`team/${id}/events`);
export const getTeamPublications = (id, content) => api.get(`team/${id}/${content}`);
export const getPublicTeam = (id, content) => api.get(`public/publicTeam/${id}/${content}`);
export const getPublicTeamMembers = (id) => api.get(`public/publicTeam/${id}/members`);
export const getPublicTeamProjects = (id) => api.get(`public/publicTeam/${id}/projects`);
export const addUserToTeam = (id, email) => api.post(`team/${id}/addUser`, { email });
export const addNotRegisteredUserToTeam = (id, data) => api.post(`team/${id}/addNotRegisteredUser`, { data });
export const deleteUserFromTeam = (id, userId) => api.post(`team/${id}/deleteUser`, { userId });
export const nameChangeTeam = (id, name) => api.post(`team/${id}/nameChange`, { name });
export const createTeamProject = (id, title, website, logo) => api.post(`team/${id}/createProject`, { title, website, logo });
export const removeTeamProject = (id, projectId) => api.post(`team/${id}/deleteProject`, { projectId });
export const setMembersOrder = (id, status, place, newplace) => api.post(`team/${id}/setMembersOrder`, { status, place, newplace });

// Publication calls
export const getPublication = (id) => api.get(`publication/${id}`);
export const getPublicPublication = (id) => api.get(`public/publicPublication/${id}`);

export const updatePublication = (id, title, doi, cycloptProject) => api.put(`publication/${id}`, { title, doi, cycloptProject });
export const updatePublicationInfo = (id, updatedPublication) => api.put(`publication/${id}/info`, updatedPublication);

export const addPublicationToTeam = (id, title, doi, publicationType, publicationState) => api.post("publication/addFromTeam", { id, title, doi, publicationType, publicationState });
export const removePublication = (publicationId) => api.post("publication/delete", { publicationId });
export const addTeamAuthor = (id, authorId) => api.post(`publication/${id}/addTeamAuthor`, { authorId });
export const deleteTeamAuthorFromPublication = (id, userId) => api.post(`publication/${id}/deleteTeamAuthor`, { userId });
export const addNonTeamAuthor = (id, email) => api.post(`publication/${id}/addNonTeamAuthor`, { email });
export const deleteNonTeamAuthorFromPublication = (id, userId) => api.post(`publication/${id}/deleteNonTeamAuthor`, { userId });
export const addExternalAuthor = (id, fullname) => api.post(`publication/${id}/addExternalAuthor`, { fullname });
export const addDocumentVersionPublication = (id, folder, originalName, savedName, comments) => api.post(`publication/${id}/addDocument`, { folder, originalName, savedName, comments });
export const addReviewPublication = (id, folder, originalName, savedName, reviewId, comments) => api.post(`publication/${id}/addReviewDocument`, { folder, originalName, savedName, reviewId, comments });
export const deleteExternalAuthorFromPublication = (id, fullname) => api.post(`publication/${id}/deleteExternalAuthor`, { fullname });
export const statesChangePublication = (id, state) => api.post(`publication/${id}/stateChange`, { state });
export const typeChangePublication = (id, type) => api.post(`publication/${id}/typeChange`, { type });
export const titleChangePublication = (id, title) => api.post(`publication/${id}/titleChange`, { title });
export const abstractChangePublication = (id, abstract) => api.post(`publication/${id}/abstractChange`, { abstract });
export const keywordsChangePublication = (id, keywords) => api.post(`publication/${id}/keywordsChange`, { keywords });
export const liveDocumentChangePublication = (id, liveDocument) => api.post(`publication/${id}/liveDocumentChange`, { liveDocument });
export const deadlineChangePublication = (id, deadline) => api.post(`publication/${id}/deadlineChange`, { deadline });
export const publishedInfoChangePublication = (id, citation, url, publicationDate) => api.post(`publication/${id}/publishedInfoChange`, { citation, url, publicationDate });
export const targetChangePublication = (id, title, url, template) => api.post(`publication/${id}/targetChange`, { title, url, template });
export const addReviewingProcess = (id, type) => api.post(`publication/${id}/addReviewingProcess`, { type });
export const addTeamMemberReviewer = (id, reviewId, reviewerId) => api.post(`publication/${id}/addTeamMemberReviewer`, { reviewId, reviewerId });
export const addNonTeamMemberReviewer = (id, reviewId, email) => api.post(`publication/${id}/addNonTeamMemberReviewer`, { reviewId, email });
export const deleteReviewerFromReview = (id, reviewId, reviewerId) => api.post(`publication/${id}/deleteReviewerFromReview`, { reviewId, reviewerId });
export const deleteReviewFromPublication = (id, reviewId) => api.post(`publication/${id}/deleteReviewFromPublication`, { reviewId });
export const deleteFileVersionFromPublication = (id, fileId) => api.post(`publication/${id}/deleteFileVersionFromPublication`, { fileId });
export const setDocumentForReview = (id, reviewingProcessId, savedName, folder, version) => api.post(`publication/${id}/setDocumentForReview`, { reviewingProcessId, savedName, folder, version });
export const addRevisedDocument = (id, reviewingProcessId, originalName, savedName, folder) => api.post(`publication/${id}/addRevisedDocument`, { reviewingProcessId, originalName, savedName, folder });
export const addReviewersComments = (id, reviewingProcessId, originalName, savedName, folder) => api.post(`publication/${id}/addReviewersComments`, { reviewingProcessId, originalName, savedName, folder });
export const addResponseToReviewers = (id, reviewingProcessId, originalName, savedName, folder) => api.post(`publication/${id}/addResponseToReviewers`, { reviewingProcessId, originalName, savedName, folder });
export const lockReviewingProcess = (id, reviewingProcessId) => api.post(`publication/${id}/lockReviewingProcess`, { reviewingProcessId });

export const addAssignment = (id, authorId, text, deadline) => api.post(`publication/${id}/addAssignment`, { authorId, text, deadline });
export const editAssignment = (id, assignmentId, assigneeId, text, deadline) => api.put(`publication/${id}/editAssignment`, { assignmentId, assigneeId, text, deadline });
export const deleteAssignment = (id, assignmentId) => api.post(`publication/${id}/deleteAssignment`, { assignmentId });
export const toggleAssignment = (id, assignmentId) => api.post(`publication/${id}/toggleAssignment`, { assignmentId });

export const addPreprintPublication = (id, folder, originalName, savedName) => api.post(`publication/${id}/addPreprintDocument`, { folder, originalName, savedName });
export const addCameraReadyPublication = (id, folder, originalName, savedName) => api.post(`publication/${id}/addCameraReadyDocument`, { folder, originalName, savedName });
export const deadlineChangeReview = (id, reviewId, deadline) => api.post(`publication/${id}/reviewDeadlineChange`, { reviewId, deadline });

export const addPublicationComment = (id, mentions, comment) => api.post(`publication/${id}/addPublicationComment`, { mentions, comment });
export const editPublicationComment = (id, commentId, mentions, comment) => api.put(`publication/${id}/editPublicationComment`, { commentId, mentions, comment });
export const deletePublicationComment = (id, commentId) => api.post(`publication/${id}/deletePublicationComment`, { commentId });

export const moveAuthor = (id, fullname, place, newplace) => api.post(`publication/${id}/moveAuthor`, { fullname, place, newplace });
export const saveMarkdown = (id, markdown) => api.put(`publication/${id}/saveMarkdown`, { markdown });
// publication assets
export const addAssetToPublication = (id, folder, originalName, savedName, assetType, title, link) => api.post(`publication/${id}/addAssetToPublication`, { folder, originalName, savedName, assetType, title, link });
export const getPublicationAssets = (id) => api.get(`publication/${id}/getPublicationAssets`);
// assets
export const editPublicationAsset = (id, title, link) => api.put(`asset/${id}`, { title, link });
export const deletePublicationAsset = (id) => api.delete(`asset/${id}`);
// assignments
export const editPublicationAssignment = (id, resolved) => api.put(`assignment/${id}`, { resolved });
export const getPublicationAssignments = (publicationId) => api.get(`assignment/?publicationId=${publicationId}`);

// Project calls
export const getProject = (id) => api.get(`project/${id}`);
export const getPublicProject = (id) => api.get(`public/publicProject/${id}`);
export const saveProjectInformation = (id, key, information) => api.put(`project/${id}`, { key, information });

// Members calls
export const saveMemberInformation = (id, data) => api.put(`member/${id}`, data);

export const addSupervisor = (id, authorId, isMain) => api.post(`publication/${id}/addSupervisor`, { authorId, isMain });
export const deleteSupervisorFromPublication = (id, userId) => api.post(`publication/${id}/deleteSupervisor`, { userId });

export const addCorrespondingAuthor = (id, userId, authorName) => api.post(`publication/${id}/addCorrespondingAuthor`, { userId, authorName });
export const removeCorrespondingAuthor = (id, userId, authorName) => api.post(`publication/${id}/removeCorrespondingAuthor`, { userId, authorName });

export const addTagToTeam = (id, tag) => api.post(`team/${id}/addTag`, { tag });
export const deleteTagFromTeam = (id, tag) => api.post(`team/${id}/deleteTag`, { tag });
export const updateTag = (id, tagToUpdate, updatedTag) => api.put(`team/${id}/updateTag`, { tagToUpdate, updatedTag });

export const setArchivedStatus = (id, archived) => api.post(`publication/${id}/setArchiveStatus`, { archived });
